import React from 'react';
import classNames from '../helpers/classNames';

export const Headline = (props) => {
  return <h1 {...props} className={classNames(props.className, 'headline')}>{props.children}</h1>
}

export const Title = (props) => {
  return <h2 {...props} className={classNames(props.className, 'title')}>{props.children}</h2>
}

export const Subheader = (props) => {
  return <h3 {...props} className={classNames(props.className, 'subheader')}>{props.children}</h3>
}

export const Quote = (props) => {
  return <blockquote {...props} className={classNames(props.className, 'quote')}>{props.children}</blockquote>
}

export const BodyHeader = (props) => {
  return <span {...props} className={classNames(props.className, 'body-header')}>{props.children}</span>
}

export const BodyText = (props) => {
  return <p {...props} className={classNames(props.className, 'body-text')}>{props.children}</p>
}

export const SecondaryBody = (props) => {
  return <p {...props} className={classNames(props.className, 'secondary-body')}>{props.children}</p>
}

export const ButtonText = (props) => {
  return <span {...props} className={classNames(props.className, 'button-text')}>{props.children}</span>
}
