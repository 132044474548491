import React from 'react';
import classNames from '../helpers/classNames';
import style from './icon.module.scss';
type IconProps = {
  children: string;
  className?: string;
};
export const Icon = ({ className, children }: IconProps) => {
  return <i  className={classNames(className, style.materialIcons)}>{children}</i>;
};


type iconProps = {
    fill?: string;
    style?: any;
    className?: string;
  };
  
  
  
  export const TwitterIcon = ({ fill, className }: iconProps) => (
    <svg
      fill={fill || '#000000'}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
    </svg>
  );
  export const FacebookIcon = ({ fill, className }: iconProps) => (
    <svg
      fill={fill || '#000000'}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
    </svg>
  );
  export const LinkedinIcon = ({ fill, className }: iconProps) => (
    <svg
      fill={fill || '#000000'}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="40px"
      height="40px"
      viewBox="-8 -8 40 40"
    >
      <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />{' '}
    </svg>
  );
  
  
  export const CircleIcon = ({ className }: iconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      width="35px"
      height="35px"
    >
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
  
  export const URLIcon = ({ url, className }: iconProps & { url: string }) => (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      width="35px"
      height="35px"
      className={className}
    >
      <image width="24px" height="24px" href={url} />
    </svg>
  );
  