import React from 'react';
import style from './buttons.module.scss';
import classNames from '../helpers/classNames';
import { Link } from 'gatsby';

// HTML Buttons

export const FlatButton = (props) => (
  <button {...props} className={classNames(props.className, style.flatButton)}>{props.children}</button>
)

export const PrimaryButton = (props) => (
  <button {...props} className={classNames(props.className, style.primaryButton)}>{props.children}</button>
)

export const SecondaryButton = (props) => (
  <button {...props} className={classNames(props.className, style.secondaryButton)}>{props.children}</button>
)

export const TertiaryButton = (props) => (
  <button {...props} className={classNames(props.className, style.tertiaryButton)}>{props.children}</button>
)

// Gatsby Link components
export const PrimaryLinkButton = (props) => (
  <Link {...props} className={classNames(props.className, style.primaryButton)}>{props.children}</Link>
)

export const SecondaryLinkButton = (props) => (
  <Link {...props} className={classNames(props.className, style.secondaryButton)}>{props.children}</Link>
)

export const TertiaryLinkButton = (props) => (
  <Link {...props} className={classNames(props.className, style.tertiaryButton)}>{props.children}</Link>
)

// HTML Anchor tags
export const PrimaryAnchorButton = (props) => (
  <a {...props} className={classNames(props.className, style.primaryButton)} rel="noopener noreferrer">{props.children}</a>
)

export const SecondaryAnchorButton = (props) => (
  <a {...props} className={classNames(props.className, style.secondaryButton)} rel="noopener noreferrer">{props.children}</a>
)

export const TertiaryAnchorButton = (props) => (
  <a {...props} className={classNames(props.className, style.tertiaryButton)} rel="noopener noreferrer">{props.children}</a>
)
