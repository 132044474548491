import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Title, Meta } from 'react-head';
import { DataComponent } from '../helpers/dataHelpers';
const PRODUCTION = 'Production';
type SEOProps = {
  title?: string;
  description?: string;
  url?: string;
  image?: any;
};
const SEO = ({
  title = undefined,
  description = undefined,
  url = undefined,
  image = undefined,
}: SEOProps) => {
  const data = useStaticQuery<GatsbyTypes.SEOQuery>(graphql`
    query SEO {
      site {
        siteMetadata {
          title
          description
          baseUrl
          twitter
          environment
        }
      }
      file(relativePath: { eq: "centeva-banner.png" }) {
        id
        name
        childImageSharp {
          id
          fixed {
            src
          }
        }
      }
    }
  `);
  return <SEOInner {...{title, description, url, image, data}} />;
};
const SEOInner: DataComponent< GatsbyTypes.SEOQuery, SEOProps> = ({title, description, url, image, data}) => {
  
  const defaults = data?.site?.siteMetadata;
  if (defaults?.baseUrl === '') {
    console.error('Please set a baseUrl in your site metadata!');
    return <></>;
  }
  title = title || defaults?.title;
  description = description || defaults?.description;
  const fullUrl = new URL(url || '', defaults?.baseUrl);
  const fullImage = image
    ? new URL(image, defaults?.baseUrl)
    : new URL(data?.file?.childImageSharp?.fixed?.src || '', defaults?.baseUrl);
  return (
    <>
      {
        defaults?.environment !== PRODUCTION && <Meta name="robots"></Meta>
      }
      <Title>{title}</Title>
      <Meta name="title" content={title} />
      <Meta name="google-site-verification" content="NO8SLq5M0GzJZl2lyRd5p35E04IB8SrpLW6UEwDKzgE" />
      <Meta name="description" content={description} />
      {fullImage && <Meta name="image" content={fullImage.toString()} />}
      <Meta property="og:url" content={fullUrl.toString()} />
      <Meta property="og:type" content="article" />
      <Meta property="og:title" content={title} />
      <Meta property="og:description" content={description} />
      {fullImage && <Meta property="og:image" content={fullImage.toString()} />}
      <Meta name="twitter:card" content="summary_large_image" />
      <Meta name="twitter:creator" content={defaults?.twitter} />
      <Meta name="twitter:title" content={title} />
      <Meta name="twitter:description" content={description} />
      {fullImage && <Meta name="twitter:image" content={fullImage.toString()} />}
    </>
  );
};
export default SEO;
