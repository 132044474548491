import React from 'react';
import { Link } from 'gatsby';
import style from './footer.module.scss';
// @ts-ignore
import backgroundImage from '../../static/backgrounds/footer-horizontal.jpg';

const Footer = ({ className }: any) => {
  return <footer className={`${className} ${style.footerWithBackground}`}
    style={{
      backgroundImage: `url(${backgroundImage})`
    }}>
    <nav>
      <div className={style.tintedBackground}>
        <ul className={style.linkList}>
          <li>
            <Link className={style.linkText} to="/about/">About Centeva</Link>
          </li>
          <li>
            <Link className={style.linkText} to="/contact/">Contact Us</Link>
          </li>
          <li>
            <Link className={style.linkText} to="/terms/">Terms of Use</Link>
          </li>
          <li>
            <Link className={style.linkText} to="/privacy/">Privacy Policy</Link>
          </li>
          {/* <li>
            <Link className={style.linkText} to="/insights">News</Link>
          </li> */}
        </ul>
        <div className={style.copyright}>© 2022 Centeva, LLC. All Rights Reserved</div>
      </div>
    </nav>
  </footer>
};

export default Footer;
