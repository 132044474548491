import React, { useEffect, useState } from 'react';
import style from './betterHeader.module.scss';

import { Link } from 'gatsby';
// @ts-ignore
import white_logo from '../../../static/header/white-logo.png';
// @ts-ignore
import color_logo from '../../../static/header/color-logo.png';
import { CapabilitiesDetails } from './CapabilitiesDetails';
import { ProductDetails } from './ProductDetails';
import { Icon } from '../icon';

const linkList = [
  { name: 'Capabilities', details: <CapabilitiesDetails></CapabilitiesDetails> },
  { name: 'Markets', path: '/markets' },
  { name: 'Products', details: <ProductDetails></ProductDetails> },
  { name: 'About Centeva', path: '/about' },
  { name: 'Insights & Trends', path: '/insights' },
  { name: 'Team', path: '/team' },
  { name: 'Contract Vehicles', path: '/contract-vehicles' },
  { name: 'Contact Us', path: '/contact' },
];

export function DetailsLink({ link, tababble, onLinkSelected }) {

  return (
    <div tabIndex={tababble ? 0 : -1}
         onKeyDown={(e) => { e.key === 'Enter' && onLinkSelected()}}
         onClick={onLinkSelected} className={style.link}>
           <span className={style.detailName}>{link.name} </span>
           <Icon className={style.headerArrow}>arrow_forward</Icon></div>);
}

export function NavLink({ link, tababble }) {
  return (<Link tabIndex={tababble ? 0 : -1} className={style.link} to={link.path}>{link.name}</Link>);
}

export default function BetterHeader( {plainBackground = false}) {


  let [expanded, setExpanded] = useState(false);
  let [links, setLinks] = useState(linkList);
  let [currentLink, setCurrentLink] = useState<string | undefined>(undefined);


  let detailMap = {};

  for (let v of linkList) {
    v.details && (detailMap[v.name] = v);
  }

  return (
    <header className={`${style.wrapper} ${expanded ? style.wrapperExpanded : ''}`}
            onMouseLeave={() => setExpanded(false)}>
      <div className={expanded || plainBackground ? style.topHeadingExpanded : style.topHeading}>
        <Link to="/">
          {
            expanded || plainBackground ?
              <img className={style.logo} src={color_logo} alt="Centeva"/> :
              <img className={style.logo} src={white_logo} alt="Centeva"/>
          }
        </Link>
        <div className={style.separator}/>
        <button className={style.menuButton} onClick={() => expanded ? setExpanded(false) : setExpanded(true)}>
          {expanded ?
            <Icon className={style.menuIcon}>close</Icon>:
            <Icon className={style.menuIcon}>menu</Icon>
          }
        </button>
        <Link to="/search/">
          <Icon className={style.headerSearchIcon}>search</Icon>
        </Link>
      </div>
      {expanded &&
      <div className={style.headerExpanded}>
        <nav>
          <ul>
            {links.map(l =>
              <li key={l.name} className={l.details && currentLink === l.name ? style.detailActive : ''}>{
                !l.details ?
                  <NavLink link={l} tababble={expanded}></NavLink> :
                  <DetailsLink link={l} tababble= {expanded} onLinkSelected={() => setCurrentLink(l.name)}></DetailsLink>
              }
              </li>)
            }
            <li class>
              <a class="betterHeader-module--link--2K3aC" href="https://www.mymedicalshopper.com/mrf/centeva-llc" target="_blank">
                My Medical Shopper
              </a>
            </li>
          </ul>
        </nav>
        {
          currentLink && 
          <div className={style.details}>{detailMap[currentLink].details}</div>
        }
      </div>}
    </header>
  );

}
