import React from 'react';
import style from './betterHeader.module.scss';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { DataComponent, PropComponent } from '../../helpers/dataHelpers';

export const CapabilitiesDetails:PropComponent<{}> = () => {

    let data = useStaticQuery<GatsbyTypes.CapabilitiesQuery>(graphql`
    query Capabilities {
    allContentfulCapabilityCategory {
        edges {
            node {
                capability {
                    slug
                    name
                }
                title
                slug
                contentful_id
            }
        }
    }
    allContentfulCapability {
        edges {
            node {
                category {
                    contentful_id
                }
                name
                slug
                contentful_id
            }
        }
    }
}`);

    return <CapabilitiesDetailsInner {...{data}} />;
   
}

const CapabilitiesDetailsInner: DataComponent<GatsbyTypes.CapabilitiesQuery> = ({data}) => {
    let categories = data.allContentfulCapabilityCategory.edges.map(c => ({...c, categories: data.allContentfulCapability.edges.filter(x => x?.node?.category?.contentful_id === c.node.contentful_id)}));

    return (<div className={style.cardWrapper}>
        {categories.map(category => <div key={category.node.title} className={style.card}>
            <div className={style.cardLabel}><Link className={style.cardLink} to={category?.node?.slug}>{category.node.title}</Link></div>
            <nav>
                {category.categories && 
                    <ul>
                        {category.categories.map(c => c.node).map(link => <li key={link.name}><Link tabIndex={0} className={style.cardLink} to={`capabilities/${link.slug}`}>{link.name}</Link></li>)}
                    </ul>
                }
            </nav>
        </div>)}
    </div>);
}
