import React, { useRef } from 'react';
import Footer from './footer';
import Meta from '../components/seo';
// @ts-ignore
import backgroundImage from '../../static/backgrounds/insightsBack.jpg';
import style from './pageWithHeader.module.scss';
import BetterHeader from './header/betterHeader';
import { Headline } from '../components/typography';
import SEO from '../components/seo';

type PageWithHeaderProps = {
  children?: any;
  headerContent?: any;
  title?: string;
  url?: string;
  description?: string;
  image?: any;
  headerImage?: any;
};
const PageWithHeader = ({
  children,
  headerContent,
  title,
  url,
  description,
  image,
  headerImage,
}: PageWithHeaderProps) => {
  const greenHeaderRef = useRef(null);
  const imgBackRef = useRef(null);
  const stickyContentRef = useRef(null);
  /*
  This logic helps the green part of the header and the page title stick
  to the top of the screen when scrolling
  */
  // useEffect(() => {
  //   function scrollingHeader() {
  //     if (imgBackRef.current != null && greenHeaderRef.current != null && stickyContentRef != null) {
  //       const greenHeaderHeight = greenHeaderRef.current.clientHeight;
  //       const offset = (imgBackRef.current.clientHeight - greenHeaderHeight);

  //       if (window.pageYOffset > offset) {
  //         greenHeaderRef.current.classList.add(style.sticky);
  //         imgBackRef.current.style.top = `-${offset}px`;
  //         imgBackRef.current.classList.add(style.stickyImgBackground);
  //         stickyContentRef.current.classList.add(style.stickyContentPadding);
  //       } else {
  //         greenHeaderRef.current.classList.remove(style.sticky);
  //         imgBackRef.current.style.top = `0px`;
  //         imgBackRef.current.classList.remove(style.stickyImgBackground);
  //         stickyContentRef.current.classList.remove(style.stickyContentPadding);
  //       }
  //     }
  //   }
  //   let originalValue = window.onscroll;
  //   if (typeof window !== `undefined`) {
  //     // When the user scrolls the page, execute myFunction
  //     window.onscroll = scrollingHeader;
  //   }
  //   return () => {
  //     if (typeof window !== `undefined`) {
  //       window.onscroll = originalValue;
  //     }
  //   };
  // }, []);

  return (
    <>
      <SEO title={title}  url={url} description={description} image={image}></SEO>
      <main className={style.layoutRoot}>
        <BetterHeader />
        {headerContent ? (
          headerContent
        ) : (
          <div
            className={style.backgroundHolder}
            style={{
              backgroundImage: `url(${headerImage || backgroundImage})`,
            }}
            ref={imgBackRef}
          >
            <div className={style.greenBanner} ref={greenHeaderRef}>
              <div className={style.alignText}>
                <Headline className={style.headerText}>{title}</Headline>
              </div>
            </div>
          </div>
        )}
        <div className={style.centeredContent} ref={stickyContentRef}>
          <div className={style.normalContainer}>{children}</div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default PageWithHeader;
