import React from 'react';
import style from './betterHeader.module.scss';
import { useStaticQuery, Link } from 'gatsby';
import { graphql } from 'gatsby';
import { DataComponent, PropComponent } from '../../helpers/dataHelpers';

export const ProductDetails: PropComponent<{}> = () => {
    let data = useStaticQuery<GatsbyTypes.ProductsQuery>(graphql`
    query Products {
    allContentfulProduct {
        edges {
            node {
                slug
                dateCreated
                cardTitle
                cardDescription
                cardImage {
                    localFile {
                        childImageSharp {
                            fixed {
                            src
                            }
                        }
                    }
                }
            }
        }
    }
}`);
    return <ProductDetailsInner {...{data}  } />;
}

const ProductDetailsInner: DataComponent<GatsbyTypes.ProductsQuery> = ({data}) => {
    return (<div className={`${style.cardWrapper} ${style.productCard}`}>
    {data.allContentfulProduct.edges.map(product => <div key={product.node.cardTitle} className={style.card}>
        <Link tabIndex={0} to={`product/${product.node.slug}`}>
            <div className={style.cardLabel}>{product.node.cardTitle}</div>
            <img className={style.productImage} alt={product.node.cardTitle} src={product?.node?.cardImage?.localFile?.childImageSharp?.fixed?.src}></img>
            <div className={style.cardDescription}>{product.node.cardDescription}</div>
        </Link>
    </div>)}
</div>);
}